import React, { useState } from 'react';
import { TextInput, Image, Text, View, Platform, TouchableOpacity } from 'react-native';
import Button from '../../components/Button/Button';
import DefaultModal from '../../components/DefaultModal/DefaultModal';
import handleLink from '../../helpers/handle-link';
import { validateRMTPUrl, validateUrl } from '../../helpers/validators';
import type { Channel } from '../../socket/document.type';
import styles from './EditChannelModal.styles';

interface Props {
  isAddVariant?: boolean;
  channel: Partial<Channel>;
  handleDismiss: () => void;
  handleSave: (channel: Partial<Channel>) => void;
}

const EditChannelModal: React.FC<Props> = ({
  channel,
  handleDismiss,
  handleSave,
  isAddVariant = false,
}) => {
  const [data, setData] = useState({ ...channel });

  const handleChange = (field: keyof typeof data) => (value: string) => {
    setData(d => ({ ...d, [field]: value }));
  };

  const handleSaveInternal = () => {
    handleSave(data);
  };

  const isRMTPvalid = validateRMTPUrl(data.RtmpUrl);
  const isWebUrlValid = !data.WebUrl || validateUrl(data.WebUrl);

  return (
    <DefaultModal
      title={`${isAddVariant ? 'Add' : 'Edit'} ${data.Name}`}
      onDismiss={handleDismiss}
      onConfirm={handleSaveInternal}
      confirmText={isAddVariant ? 'Add channel' : 'Save'}
      dismissText="Cancel"
      icon={
        <Image
          style={styles.channelImage}
          source={{ uri: `https://de10.streamster.io:6001/Files/targets/${channel.TargetId}.png` }}
        />
      }
    >
      <View style={styles.rowWithMargin}>
        <Text style={styles.label}>Name</Text>
        <View style={styles.inputGroup}>
          <TextInput
            maxLength={1024}
            style={[styles.input, styles.inputWide]}
            value={data.Name}
            onChangeText={handleChange('Name')}
          />
          <Text style={styles.tip}>Give any short name or leave default</Text>
        </View>
      </View>
      <View style={styles.rowWithMargin}>
        <Text style={styles.label}>Web shortcut</Text>
        <View style={styles.inputGroup}>
          <View style={styles.row}>
            <TextInput
              maxLength={1024}
              style={[styles.input, styles.inputWide, !isWebUrlValid && styles.inputError]}
              value={data.WebUrl}
              onChangeText={handleChange('WebUrl')}
            />
            <Button
              disabled={!validateUrl(data.WebUrl)}
              style={styles.testButton}
              textStyle={styles.testButtonText}
              onPress={() =>
                Platform.OS === 'web'
                  ? window.open(data.WebUrl, '_blank')
                  : handleLink(data.WebUrl || '')
              }
            >
              Test
            </Button>
          </View>
          <Text style={isWebUrlValid ? styles.tip : styles.tipError}>
            Configure link to your account for quick access from the main page
          </Text>
        </View>
      </View>
      <View style={styles.rowWithMargin}>
        <Text style={styles.label}>Target</Text>
        <View style={styles.inputGroup}>
          <View style={styles.rowWithMargin}>
            <Text style={[styles.label, styles.labelSmall]}>RTMP Url</Text>
            <View style={styles.inputGroup}>
              <TextInput
                maxLength={1024}
                style={[styles.input, styles.inputWide, !isRMTPvalid && styles.inputError]}
                value={data.RtmpUrl}
                onChangeText={handleChange('RtmpUrl')}
              />

              <Text style={isRMTPvalid ? styles.tip : styles.tipError}>
                {data.RtmpUrl
                  ? isRMTPvalid
                    ? 'Provide none-empty rtmp url'
                    : 'Provide rtmp url in correct format like "rtmp://host.com/app"'
                  : 'Provide none-empty rtmp url'}
              </Text>
            </View>
          </View>
          <View style={styles.rowWithMargin}>
            <Text style={[styles.label, styles.labelSmall]}>Stream Key</Text>
            <View style={styles.inputGroup}>
              <TextInput
                maxLength={1024}
                style={[styles.input, styles.inputWide, !data.Key && styles.inputError]}
                value={data.Key}
                onChangeText={handleChange('Key')}
              />
              <Text style={data.Key ? styles.tip : styles.tipError}>
                Provide key or stream name
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => handleLink(`https://streamster.io/manuals/${channel.TargetId}`, '_blank')}
          >
            <Text style={styles.helpText}>
              You are not sure how to get the values? Check out our help
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </DefaultModal>
  );
};

export default EditChannelModal;
