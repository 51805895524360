import React from 'react';
import { ViewStyle, StyleProp, StyleSheet, ActivityIndicator } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({ loader: { margin: 'auto' } });

interface Props {
  size?: 'small' | 'large' | number;
  style?: StyleProp<ViewStyle>;
  color?: string;
}

const Loader = ({ size = 'large', style, color }: Props) => {
  return (
    <ActivityIndicator
      size={size}
      color={color || mixins.color.white}
      style={[styles.loader, style]}
    />
  );
};

export default Loader;
