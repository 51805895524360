import {
  useBusinessTariffsQuery,
  useChangeBusinessTariffRequest,
  useChangeBusinessUserPasswordRequest,
  useDeleteBusinessUserMutation,
  useMyTariffQuery,
} from '#/api/api.portal';
import mixins from '#/app/styles';
import GridIcon from '#/assets/icons/grid.svg';
import DollarIcon from '#/assets/icons/currency-usd.svg';
import ViewListIcon from '#/assets/icons/view-list.svg';
import StatisticsIocn from '#/assets/icons/chart-bar.svg';
import { BusinessUserGrid } from '#/components/BusinessUserGrid/BusinessUserGrid';
import { BusinessUserList } from '#/components/BusinessUserList/BusinessUserList';
import { BusinessUserManageList } from '#/components/BusinessUserManageList/BusinessUserManageList';
import Button from '#/components/Button/Button';
import Heading from '#/components/Heading/Heading';
import Loader from '#/components/Loader/Loader';
import Select from '#/components/Select/Select';
import Tooltip from '#/components/Tooltip';
import {
  BusinessUserOrderOptionsType,
  businessUsersOrderOptions,
  useBusinessUsers,
} from '#/hooks/use-business-users';
import { AddNewBusinessUserModal } from '#/screens/home/business/AddNewBusinessUserModal';
import { useMemo, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { BusinessUsersStatistics } from '#/components/BusinessUsersStatistics/BusinessUsersStatistics';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';

const styles = StyleSheet.create({
  addButton: {
    alignSelf: 'flex-start',
    marginTop: mixins.indent.i8,
  },
  actionRow: {
    flexDirection: 'row',
    marginBottom: mixins.indent.i2,
    alignItems: 'center',
  },
  wrapper: {
    maxWidth: 1300,
  },
  headerControls: {
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    gap: 10,
  },
  select: {
    paddingVertical: 5,
    marginLeft: mixins.indent.i10,
    width: 150,
    backgroundColor: mixins.color.dark,
    borderColor: mixins.color.dark,
  },
  activeView: {
    borderColor: mixins.color.blue,
  },
  listButton: {
    borderWidth: 2,
    borderColor: mixins.color.dark,
    borderStyle: 'solid',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const HomeScreenBusiness = () => {
  const [listType, setListType] = useState<'list' | 'grid' | 'manage-list' | 'stats'>('list');
  const [isAddModalVisible, setAddModalVisibility] = useState(false);
  const [order, setOrder] = useState<BusinessUserOrderOptionsType>(
    businessUsersOrderOptions[0].value,
  );
  const { data: tariffs = [] } = useBusinessTariffsQuery();
  const { userList, profile, users } = useBusinessUsers(order);
  const { selectedUserId, selectUser } = useSelectedBusinessUserStore();
  const { data: adminUserTariff } = useMyTariffQuery({ enabled: listType === 'manage-list' });
  const [changePassword] = useChangeBusinessUserPasswordRequest();
  const deleteMutation = useDeleteBusinessUserMutation();
  const changeTariffMutation = useChangeBusinessTariffRequest();

  const isLoading = !users || !profile;

  const globalTariffs = useMemo(() => tariffs.filter(t => t.type === 'standard'), [tariffs]);

  const onChangeTariff = (userId: number, tariffId: number) =>
    changeTariffMutation.mutateAsync({
      userId,
      tariffId,
    } as any);

  return (
    <View style={styles.wrapper}>
      <View style={styles.actionRow}>
        <Heading>Users</Heading>
        <View style={styles.headerControls}>
          <Tooltip text="List view">
            <Pressable
              style={[styles.listButton, listType === 'list' && styles.activeView]}
              onPress={() => setListType('list')}
            >
              <ViewListIcon height={25} fill={mixins.color.white} title="List view" />
            </Pressable>
          </Tooltip>
          <Tooltip text="Card view">
            <Pressable
              style={[styles.listButton, listType === 'grid' && styles.activeView]}
              onPress={() => setListType('grid')}
            >
              <GridIcon height={20} fill={mixins.color.white} />
            </Pressable>
          </Tooltip>
          <Tooltip text="Accounting">
            <Pressable
              style={[styles.listButton, listType === 'manage-list' && styles.activeView]}
              onPress={() => setListType('manage-list')}
            >
              <DollarIcon height={25} fill={mixins.color.white} />
            </Pressable>
          </Tooltip>
          <Tooltip text="Statistics">
            <Pressable
              style={[styles.listButton, listType === 'stats' && styles.activeView]}
              onPress={() => setListType('stats')}
            >
              <StatisticsIocn height={25} color={mixins.color.white} />
            </Pressable>
          </Tooltip>
          <Select
            style={styles.select}
            values={businessUsersOrderOptions}
            value={order}
            handleChange={setOrder}
          />
        </View>
      </View>
      {isLoading && <Loader size={50} />}
      {listType === 'list' && (
        <BusinessUserList
          users={userList}
          selectUser={selectUser}
          selectedUserId={selectedUserId}
        />
      )}
      {listType === 'grid' && (
        <BusinessUserGrid
          users={userList}
          tariffs={tariffs}
          selectUser={selectUser}
          selectedUserId={selectedUserId}
        />
      )}
      {listType === 'manage-list' && (
        <BusinessUserManageList
          users={userList}
          tariffs={tariffs}
          selectUser={selectUser}
          selectedUserId={selectedUserId}
          adminUserTariff={adminUserTariff}
          onChangePassword={changePassword}
          onDeleteUser={deleteMutation.mutateAsync as any}
          onChangeTariff={onChangeTariff}
          requestPending={deleteMutation.isLoading || changeTariffMutation.isLoading}
        />
      )}
      {listType === 'stats' && <BusinessUsersStatistics />}
      {listType !== 'stats' && (
        <Button onPress={() => setAddModalVisibility(true)} style={styles.addButton}>
          Add new
        </Button>
      )}
      {isAddModalVisible && (
        <AddNewBusinessUserModal
          tariffs={globalTariffs}
          onDismiss={() => setAddModalVisibility(false)}
        />
      )}
    </View>
  );
};
