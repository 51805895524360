/* eslint-disable camelcase */
import React from 'react';
import qs from 'qs';

import connect from '../../store/store';

import type { Props, AuthState } from './auth.type';

// const
import { defaultState, SCOPE, GRUNT_TYPE, CLIENT_SECRET, AUTH } from './auth.const';

// context
import { AppContext } from '../../app/app.provider';
import { PLATFORM_NAME, UNIQUE_ID, VERSION } from '../../utils';
import { API_HOST_URL } from '../../api/api.const';
import type { StackParamList } from '../../navigation/navigation.types';
import { Buffer } from 'buffer';
import { Platform } from 'react-native';
import { AuthContext, authStoreRef, getAuthStore } from './auth.utils';
import { queryClient } from '#/app/app.queryClient';
import { retryIf } from '#/utils/retry';

export class AuthProvider extends React.Component<Props, AuthState> {
  constructor(props) {
    super(props);
    this.state = {
      context: {
        ...props.state,
        login: this.login,
        logout: this.logout,
        register: this.register,
        refreshWeb: this.refreshWeb,
        requestPasswordChange: this.requestPasswordChange,
        refreshTokens: this.refreshTokens,
        resetPassword: this.resetPassword,
        deleteAccount: this.deleteAccount,
        activate: this.activate,
        accountActivated: this.accountActivated,
        isAccountActive: this.isAccountActive,
        resendVerificationEmail: this.resendVerificationEmail,
        submitTwoFactorCode: this.submitTwoFactorCode,
      },
    };
  }

  static contextType = AppContext;
  declare context: React.ContextType<typeof AppContext>;

  decodeToken = (token: string) =>
    JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('ascii'));

  login = async (data: { username: string; password: string; redirect?: keyof StackParamList }) => {
    const { password, username, redirect } = data;
    try {
      const appResponse = await this.context.api(`${API_HOST_URL}/authorize`, {
        method: 'POST',
        body: {
          login: username,
          password,
        },
      });

      if (appResponse.data.result === '2FA_SENT') {
        return { result: '2FA', tokenId: appResponse.data.tokenId };
      }

      const response = await retryIf({
        action: () =>
          this.context.api(
            'connect/token',
            {
              method: 'POST',
              body: qs.stringify({
                ver: VERSION,
                username,
                password,
                scope: SCOPE,
                client_id: PLATFORM_NAME,
                grant_type: GRUNT_TYPE,
                client_secret: CLIENT_SECRET,
                iid: UNIQUE_ID,
              }),
            },
            {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          ),
        shouldRetry: (err: any) => {
          if (err?.payload?.data?.error_description === 'invalid_username_or_password') {
            return true;
          }

          return false;
        },
        tries: 5,
      });

      const authData = {
        isAuth: true,
        accessToken: response.data.access_token,
        decodedAccessToken: this.decodeToken(response.data.access_token),
        expires: response.data.expires_in,
        scope: response.data.scope,
        tokenType: response.data.token_type,
        webAuth: appResponse.data,
        username,
        password,
        redirect,
      };

      this.props.updateStore(authData);
      this.setState(prevState => ({
        context: {
          ...prevState.context,
          ...authData,
        },
      }));

      return { result: 'LOGGED_IN' };
    } catch (error) {
      throw error;
    }
  };

  submitTwoFactorCode = async (tokenId: string, code: string) => {
    const response = await this.context.api(`${API_HOST_URL}/authorize/code`, {
      method: 'POST',
      body: {
        tokenId,
        code,
        iid: UNIQUE_ID,
      },
    });

    const { serverAuth, ...webAuth } = response.data;

    const authData = {
      isAuth: true,
      accessToken: serverAuth.access_token,
      decodedAccessToken: serverAuth.access_token
        ? this.decodeToken(serverAuth.access_token)
        : undefined,
      expires: serverAuth.expires_in,
      scope: serverAuth.scope,
      tokenType: serverAuth.token_type,
      webAuth: webAuth,
      username: '',
      password: '',
    };

    this.props.updateStore(authData as any);
    this.setState(prevState => ({
      context: {
        ...prevState.context,
        ...authData,
      },
    }));
  };

  refreshTokens = async () => {
    const { username, password } = this.state.context;

    if (!username || !password) {
      switch (Platform.OS) {
        case 'web':
          return;
        default:
          return this.logout();
      }
    }

    return this.login({ username, password });
  };

  register = async ({
    email,
    login,
    password,
    couponCode,
    referralCode,
  }: {
    email: string;
    password: string;
    login: string;
    couponCode: string;
    referralCode: string;
  }) => {
    await this.context.api(`${API_HOST_URL}/register`, {
      method: 'POST',
      body: {
        login,
        email,
        password,
        couponCode,
        confirmPassword: password,
        referralCode,
      },
    });
  };

  requestPasswordChange = async (login: string) => {
    await this.context.api(`${API_HOST_URL}/forgot-password`, {
      method: 'POST',
      body: {
        login,
      },
    });
  };

  resetPassword = async ({ code, password }: { code: string; password: string }) => {
    await this.context.api(`${API_HOST_URL}/reset-password`, {
      method: 'POST',
      body: {
        code,
        password,
      },
    });
  };

  deleteAccount = async () => {
    const store = getAuthStore();

    await this.context.api(
      `${API_HOST_URL}/delete-account`,
      {
        method: 'POST',
        body: { confirm: true },
      },
      { Authorization: `Bearer ${store?.webAuth?.accessToken}` },
    );
  };

  refreshWeb = async (refreshToken: string) => {
    const appResponse = await this.context.api(
      `${API_HOST_URL}/refresh`,
      {
        method: 'POST',
      },
      { Authorization: `Bearer ${refreshToken}` },
    );

    const store = getAuthStore();

    const authData = { ...store, webAuth: appResponse.data };

    this.props.updateStore(authData);
    this.setState(prevState => ({
      context: {
        ...prevState.context,
        ...authData,
      },
    }));
  };

  activate = async (code: string) => {
    return this.context.api(`${API_HOST_URL}/activate`, {
      method: 'POST',
      body: {
        code,
      },
    });
  };

  resendVerificationEmail = async () => {
    const store = getAuthStore();

    return this.context.api(
      `${API_HOST_URL}/resend-verification-email`,
      {
        method: 'POST',
      },
      { Authorization: `Bearer ${store?.webAuth?.accessToken}` },
    );
  };

  accountActivated = () => {
    if (!this.props.state.webAuth) {
      return;
    }

    const authData = {
      ...this.props.state,
      webAuth: { ...this.props.state.webAuth, active: true },
    };

    this.props.updateStore(authData);
    this.setState(prevState => ({
      context: {
        ...prevState.context,
        ...authData,
      },
    }));
  };

  logout = () => {
    try {
      this.props.updateStore(defaultState);
      this.setState(prevState => ({
        context: {
          ...prevState.context,
          ...defaultState,
        },
      }));
      setTimeout(() => {
        queryClient.clear();
      }, 250);
      return Promise.resolve();
    } catch (e) {
      throw e;
    }
  };

  isAccountActive = () => {
    return (
      this.state.context.webAuth?.active === undefined ||
      this.state.context.webAuth?.active === true
    );
  };

  render() {
    const { context } = this.state;
    const { children } = this.props;
    authStoreRef.store = context;
    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
  }
}

const mapStateToProps = (_state, _ownProps) => ({});

export default connect(defaultState, mapStateToProps, AUTH)(AuthProvider);
