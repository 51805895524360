import React, { useContext, useEffect, useState } from 'react';
import { Pressable, View, Text } from 'react-native';
import mixins from '../../app/styles';
import DefaultModal from '../DefaultModal/DefaultModal';
import { SocketContext } from '../../socket/socket.provider';

import ChevronDoubleUpIcon from '../../assets/icons/chevron-double-up.svg';
import ChevronDoubleDownIcon from '../../assets/icons/chevron-double-down.svg';
import InformationIcon from '../../assets/icons/information-outline.svg';
import AlertIcon from '../../assets/icons/alert.svg';
import styles from './TranscodingModal.styles';
import { AuthContext } from '../../screens/auth/auth.utils';
import Select from '../Select/Select';
import Slider from '@react-native-community/slider';
import { Link } from '@react-navigation/native';
import { useIsBusinessUser } from '../../hooks';
import { Channel } from '#/socket/document.type';

interface Props {
  handleClose: () => void;
}

const ALL_RESOLUTIONS = [
  '3840x2160',
  '2560x1440',
  '1920x1080',
  '1280x720',
  '960x720',
  '960x540',
  '640x360',
];

const ALL_FPS = [60, 50, 30, 25, 20, 15, 10];

const mapToSelect = (values: any[]) => values.map(v => ({ label: v, value: v }));

const TranscodingModal: React.FC<Props> = ({ handleClose }) => {
  const isBusinessUser = useIsBusinessUser();
  const { serverData, sendPatch } = useContext(SocketContext);
  const [[transcoderId, transcoder]] = Object.entries(serverData.Transcoders);
  const { decodedAccessToken } = useContext(AuthContext);
  const [bitrate, setBitrate] = useState(transcoder.Bitrate);
  const channels = Object.entries(serverData.Channels);
  const toggleTranscoding = (channelId: string) => () => {
    sendPatch(
      ['Channels', channelId, 'TranscoderId'],
      serverData.Channels[channelId].TranscoderId ? null : transcoderId,
    );
  };

  const troOrFallback = decodedAccessToken.tro || '1920x30';

  const resolutions = ALL_RESOLUTIONS.filter(
    r => Number(r.split('x')[1]) <= Number(troOrFallback.split('x')[0]),
  );

  const fps = ALL_FPS.filter(f => f <= Number(troOrFallback.split('x')[1]));
  const encodingAvailable = Number(decodedAccessToken.tra) > 0;

  const handleUpdate = (field: keyof typeof transcoder) => (value: any) => {
    if (field === 'Resolution') {
      const [Width, Height] = value.split('x').map((v: string) => Number(v));
      sendPatch(['Transcoders', transcoderId, field], { Width, Height }, false);
    } else {
      sendPatch(['Transcoders', transcoderId, field], value);
    }
  };

  useEffect(() => {
    setBitrate(transcoder.Bitrate);
  }, [transcoder.Bitrate]);

  useEffect(() => {
    if (serverData && Object.keys(serverData.Transcoders).length === 0) {
      const randomId = Date.now().toString();
      sendPatch(['Transcoders', randomId], {
        Resolution: { Width: 1280, Height: 720 },
        Bitrate: 800,
        Fps: 30,
      } as typeof transcoder);
    }
  }, [serverData, sendPatch]);

  const renderChannel = ([channelId, channel]: [string, Channel]) => {
    const name = channel.Name || serverData.Targets[channel.TargetId]?.Name || 'Custom';
    const Icon = channel.TranscoderId ? ChevronDoubleUpIcon : ChevronDoubleDownIcon;

    return (
      <View style={styles.channelWrapper} key={channelId}>
        <Text style={styles.channelText}>{name}</Text>
        <Pressable
          disabled={!encodingAvailable}
          onPress={toggleTranscoding(channelId)}
          style={({ pressed, hovered }) => ({
            backgroundColor: pressed || hovered ? mixins.color.grayDark : 'transparent',
            ...styles.channelIcon,
          })}
        >
          <Icon fill={mixins.color.white} width={24} />
        </Pressable>
      </View>
    );
  };

  const { Settings } = serverData;

  const doesQualityExceedSource =
    transcoder.Fps > Settings.Fps ||
    transcoder.Bitrate > Settings.Bitrate ||
    transcoder.Resolution.Height > Settings.Resolution.Height ||
    transcoder.Resolution.Width > Settings.Resolution.Width;

  const filteredChannels = channels.filter(([_, c]) => Boolean(c));

  return (
    <DefaultModal
      rawContent
      title="Transcoding settings"
      onConfirm={handleClose}
      confirmText="Close"
      onDismiss={handleClose}
    >
      <View style={styles.originalWrapper}>
        <Text style={styles.title}>Original stream</Text>
        <Text style={styles.label}>Channels</Text>
        <View style={styles.channelsRow}>
          {filteredChannels.filter(([_, c]) => !c.TranscoderId).length === 0 && (
            <Text style={styles.inactive}>
              No channels configured for original stream. This is inefficient!
            </Text>
          )}
          {filteredChannels.filter(([_, c]) => !c.TranscoderId).map(renderChannel)}
        </View>
      </View>
      <View style={styles.transcodedWrapper}>
        <Text style={styles.title}>Transcoded stream</Text>
        <View style={styles.optionsRow}>
          <View style={styles.option}>
            <Text style={styles.label}>Resolution</Text>
            <Select
              disabled={!encodingAvailable}
              handleChange={handleUpdate('Resolution')}
              value={`${transcoder.Resolution.Width}x${transcoder.Resolution.Height}`}
              values={mapToSelect(resolutions)}
            />
          </View>
          <View style={styles.option}>
            <Text style={styles.label}>FPS</Text>
            <Select
              disabled={!encodingAvailable}
              style={styles.optionFps}
              handleChange={handleUpdate('Fps')}
              value={transcoder.Fps}
              values={mapToSelect(fps)}
            />
          </View>
          <View style={[styles.option, styles.optionBitrate]}>
            <Text style={styles.label}>Bitrate ({bitrate})</Text>
            <Slider
              disabled={!encodingAvailable}
              minimumValue={800}
              maximumValue={Number(decodedAccessToken.mbr)}
              onValueChange={setBitrate}
              minimumTrackTintColor={
                encodingAvailable ? mixins.color.grayDarker : mixins.color.grey
              }
              maximumTrackTintColor={
                encodingAvailable ? mixins.color.grayDarker : mixins.color.grey
              }
              style={encodingAvailable ? undefined : styles.blockedSlider}
              thumbTintColor={encodingAvailable ? mixins.color.blue : mixins.color.grayDarkest}
              step={25}
              value={bitrate}
              onSlidingComplete={handleUpdate('Bitrate')}
            />
          </View>
        </View>
        {encodingAvailable ? (
          <>
            <Text style={styles.label}>Channels</Text>
            <View style={styles.channelsRow}>
              {filteredChannels.filter(([_, c]) => c.TranscoderId).length === 0 && (
                <Text style={styles.inactive}>
                  No channels to transcode. Transcoding will be off.
                </Text>
              )}
              {filteredChannels.filter(([_, c]) => c.TranscoderId).map(renderChannel)}
            </View>
          </>
        ) : (
          <View style={styles.disabledWrapper}>
            <Text style={styles.disabledRed}>Transcoding is not included your pricing plan</Text>
            {!isBusinessUser && (
              <Link
                style={styles.disabledBlue}
                onPress={() => handleClose()}
                to={{ screen: 'Tariff' }}
              >
                Discover available pricing plans
              </Link>
            )}
          </View>
        )}
      </View>
      {doesQualityExceedSource ? (
        <View style={styles.infoWrapper}>
          <AlertIcon style={styles.infoIcon as any} fill={mixins.color.red} />
          <Text style={styles.warningText}>
            We don't recommend setting higher Resolution, FPS or Bitrate on the transcoded stream
            than the original one. It will not improve your actual video quality. Besides, it is
            prohibited by the rules of some platforms.
          </Text>
        </View>
      ) : (
        <View style={styles.infoWrapper}>
          <InformationIcon style={styles.infoIcon as any} fill={mixins.color.white} />
          <Text style={styles.infoText}>
            Transcoding allows you to change stream properties to some destinations while
            maintaining source properties to other destinations. For example, in this way you can
            have stream 5Mbps to two sites while keeping 9Mbps to some other three sites.
          </Text>
        </View>
      )}
    </DefaultModal>
  );
};

export default TranscodingModal;
