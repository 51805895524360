import React from 'react';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/account.streamster\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: event => {
      const toIgnore = [
        'Token invalid',
        'The user aborted a request',
        'Fetch is aborted',
        'ValidationError',
        'pagead',
        'Failed to fetch',
        'The operation was aborted.',
      ];

      if (event.exception?.values) {
        const msg = event.exception.values.map(e => `${e.type} ${e.value}`).join(' ');

        if (toIgnore.some(v => msg.includes(v))) {
          return null;
        }
      }

      return event;
    },
  });
}

// context
import { AppProvider } from '../app/app.provider';
import { SocketProvider } from '../socket/socket.provider';
import { ToasterProvider } from '../toaster/toaster.provider';

// componenets
import ToasterMessages from '../toaster/toaster-messages';

import Navigation from '../navigation/navigation';
import './app.styles.scss';
import { WebLayout } from '../components/WebLayout/WebLayout';
import UpdateChaportUserDetails from '../components/UpdateChaportUserDetails';
import AblyRealtime from '../components/AblyRealtime';
import GlobalModal from '../components/GlobalModal';
import AuthProvider from '../screens/auth/auth.provider';
import { queryClient } from '#/app/app.queryClient';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <ToasterProvider>
      <AppProvider>
        <AuthProvider>
          <SocketProvider>
            <WebLayout>
              <Navigation />
              <ToasterMessages />
            </WebLayout>
            <UpdateChaportUserDetails />
            <AblyRealtime />
            <GlobalModal />
          </SocketProvider>
        </AuthProvider>
      </AppProvider>
    </ToasterProvider>
  </QueryClientProvider>
);

export default App;
