import React from 'react';
import { applyPatch, compare } from 'fast-json-patch';
import { lensPath, set } from 'ramda';
import { DeviceState, Document } from './document.type';
import { Platform } from 'react-native';

import type { Context, Props, SendPatch, SocketState } from './socket.type';

// const
import { defaultState } from './socket.const';

// context
import { AppContext } from '../app/app.provider';
import HubConnect from '../socket/socket';
import { getDeviceId, getRandomId } from '../utils';
import { DefaultTranscoder, DEFAULT_VIDEO_INPUTS, getDefaultScene } from '../helpers/defaultData';

function normalizePatch(patch: string | string[]) {
  if (typeof patch === 'string') {
    const patchCorrectPath = patch.replace('"path":"/"', '"path":""');
    return JSON.parse(patchCorrectPath);
  }
  return [];
}

export const SocketContext = React.createContext<Context>(undefined!);

const BITRATE_HISTORY_SIZE = 241;

export class SocketProvider extends React.Component<Props, SocketState> {
  // eslint-disable-next-line react/sort-comp
  unsentPatches: Array<string>;
  outcome: boolean;
  timeoutId: any;
  serverData: Document;

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.timeoutId = null;
    this.unsentPatches = [];
    this.outcome = false;

    this.serverData = defaultState.serverData;

    this.state = {
      context: {
        ...defaultState,
        onJsonPatch: this.onJsonPatch,
        getCameraLayerForScene: this.getCameraLayerForScene,
        cleanServerData: this.cleanServerData,
        sendPatch: this.sendPatch,
        commonData: {
          bitrateHistory: new Array(BITRATE_HISTORY_SIZE).fill(0),
          isConnected: false,
          isWindowsAppOpen: false,
          currentDeviceType: 'ext',
          KPIs: {} as any,
          selectedScene: '',
          isStreaming: false,
          isStreamingExternalSource: false,
          selectedSceneOwner: '',
        },
      },
    };
  }

  componentDidMount() {
    this.startIntervalApplyPatch();
  }

  componentDidUpdate(prevProps, prevState) {
    const { serverData } = this.state.context;
    if (this.outcome) {
      const { serverData: prevServerData } = prevState.context;
      const diff = compare(prevServerData, serverData);
      if (diff.length && HubConnect.connection) {
        HubConnect.invokeJsonPatch(diff);
      }
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  startIntervalApplyPatch = () => {
    this.timeoutId = setInterval(() => {
      if (this.unsentPatches && this.unsentPatches.length) {
        const { serverData } = this.state.context;
        try {
          const newServerData = applyPatch(
            serverData,
            // @ts-expect-error
            this.unsentPatches,
            false,
            false,
          ).newDocument;
          if (newServerData) {
            this.setStateServerData(newServerData, false);
          }
        } catch {
          console.log('Error while trying to apply patch');
        } finally {
          this.unsentPatches = [];
        }
      }
    }, 500);
  };

  getCommonData = (serverData: Document) => {
    const selectedScene = serverData?.Settings?.SelectedScene;
    const selectedSceneOwner = serverData?.Scenes[selectedScene]?.Owner ?? null;

    const isWindowsAppOpen =
      serverData?.Devices[selectedSceneOwner]?.State !== DeviceState.Inactive;
    const isConnected = serverData?.Settings?.StreamingToCloudStarted && isWindowsAppOpen;
    const externalSource = serverData.Devices.ext || {};

    const isStreamingExternalSource = externalSource.State === DeviceState.Online;

    const KPIs = isStreamingExternalSource
      ? externalSource.KPIs
      : serverData?.Devices[selectedSceneOwner]?.KPIs;

    const bitrate = KPIs?.CloudOut?.Bitrate || 0;

    const currentDevice = serverData?.Devices[selectedSceneOwner];
    const currentDeviceType = currentDevice?.Type;

    const isStreaming =
      currentDevice?.State === DeviceState.Online
        ? currentDevice?.Type === 'ext' || serverData.Settings.StreamingToCloudStarted
        : false;

    return {
      isConnected,
      selectedScene,
      isWindowsAppOpen,
      selectedSceneOwner,
      currentDeviceType,
      KPIs: serverData?.Devices[selectedSceneOwner]?.KPIs,
      isStreamingExternalSource,
      isStreaming,
      bitrateHistory: [
        ...this.state.context.commonData.bitrateHistory,
        Number.isNaN(bitrate) ? 0 : bitrate,
      ].slice(-BITRATE_HISTORY_SIZE),
    };
  };

  initScene = (serverData: Document) => {
    if (Platform.OS === 'web') {
      return;
    }

    const [sId, scene] =
      Object.entries(serverData.Scenes).find(([_id, s]) => s.Owner === getDeviceId()) || [];

    const sceneId = sId || getRandomId();

    if (!sceneId || !scene?.Items['0']) {
      this.sendPatch(['Scenes', sceneId], getDefaultScene());
    }

    if (!serverData?.Settings?.SelectedScene) {
      this.sendPatch(['Settings', 'SelectedScene'], sceneId);
    }

    if (Object.keys(serverData.Devices[getDeviceId()].VideoInputs).length === 0) {
      this.sendPatch(['Devices', getDeviceId(), 'VideoInputs'], DEFAULT_VIDEO_INPUTS);
    }
  };

  initTranscoder = (serverData: Document) => {
    if (Object.keys(serverData.Transcoders).length > 0) {
      return;
    }

    this.sendPatch(['Transcoders', getRandomId()], DefaultTranscoder);
  };

  setStateServerData = (serverData, outcome) => {
    this.outcome = outcome;
    this.serverData = serverData;
    (window as any).d = serverData;
    this.setState(prevState => ({
      ...prevState,
      context: {
        ...prevState.context,
        serverData,
        commonData: this.getCommonData(serverData),
        didLoadData: true,
      },
    }));
    this.initScene(serverData);
    this.initTranscoder(serverData);
  };

  cleanServerData = async () => {
    this.setState(prevState => ({
      ...prevState,
      context: {
        ...prevState.context,
        ...defaultState.serverData,
        didLoadData: false,
      },
    }));
    this.serverData = defaultState.serverData;
    this.unsentPatches = [];
  };

  onJsonPatch = payload => {
    if (payload.Changes && typeof payload.Changes === 'string') {
      const patchData = normalizePatch(payload.Changes);
      if (Array.isArray(patchData)) {
        this.unsentPatches = this.unsentPatches.concat(patchData);
      }
    }
  };

  sendPatch: SendPatch = (pathOrArgs, val, out) => {
    const outcome = (Array.isArray(pathOrArgs) ? out : pathOrArgs.outcome) ?? true;
    const newValue = Array.isArray(pathOrArgs) ? val : pathOrArgs.value;
    const pathArray = Array.isArray(pathOrArgs) ? pathOrArgs : pathOrArgs.path;
    const op = Array.isArray(pathOrArgs) ? undefined : pathOrArgs.op;

    if (!outcome || op) {
      this.manuallySendPatch(`/${pathArray.join('/')}`, newValue, op);
    } else {
      const countLens = lensPath(pathArray);
      const newServerData = set(countLens, newValue, this.serverData);
      this.setStateServerData(newServerData, outcome);
    }
  };

  manuallySendPatch = (path: string, value: any, op: string) => {
    const diff = [
      {
        op: op ? op : value === undefined ? 'remove' : 'replace',
        path,
        ...(value !== undefined && { value }),
      },
    ];

    if (HubConnect.connection) {
      HubConnect.invokeJsonPatch(diff);
    }

    const newServerData = applyPatch(
      this.serverData,
      // @ts-expect-error
      diff,
      false,
      false,
    ).newDocument;
    if (newServerData) {
      this.setStateServerData(newServerData, false);
    }
  };

  getCameraLayerForScene = (sceneId: string) => {
    const {
      context: { serverData },
    } = this.state;

    const sceneItems = serverData?.Scenes[sceneId]?.Items;
    if (!sceneItems) {
      return 0;
    }
    const layersKeys = Object.keys(sceneItems);
    const cameraLayers = layersKeys.filter(item => sceneItems[item]?.Source?.Device);

    return cameraLayers.reduce((item: string | undefined, acc: string | undefined) => {
      if (
        (item !== undefined &&
          item !== null &&
          acc !== undefined &&
          sceneItems[item]?.Rect?.W > sceneItems[acc]?.Rect?.W &&
          sceneItems[item]?.Rect?.H > sceneItems[acc]?.Rect?.H) ||
        //The main layer should remain first if the two cameras are the same size
        item === acc
      ) {
        return item;
      }
    }, cameraLayers[0]);
  };

  render() {
    const { context } = this.state;
    const { children } = this.props;

    return <SocketContext.Provider value={context}>{children}</SocketContext.Provider>;
  }
}
