import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  input: {
    backgroundColor: mixins.color.dark,
    borderBottomWidth: 2,
    borderColor: mixins.color.grayDarkest,
    margin: 2,
    paddingVertical: 5,
    flexGrow: 1,
    color: mixins.color.white,
  },
  inputWide: {
    flexGrow: 1,
    minWidth: 400,
  },
  inputError: {
    borderColor: mixins.color.redDark,
  },
  inputGroup: {
    flexGrow: 1,
  },
  channelImage: {
    width: 40,
    height: 40,
  },
  row: {
    flexDirection: 'row',
  },
  rowWithMargin: {
    marginBottom: mixins.indent.i5,
    flexDirection: 'row',
  },
  label: {
    lineHeight: 40,
    width: 130,
    color: mixins.color.grey,
  },
  labelSmall: {
    width: 90,
  },
  tip: {
    fontSize: 12,
    color: mixins.color.grey,
  },
  tipError: {
    fontSize: 12,
    color: mixins.color.redDark,
  },
  helpText: {
    textDecorationColor: mixins.color.blue,
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 15,
    color: mixins.color.blue,
  },
  testButton: {
    backgroundColor: mixins.color.dark,
    marginLeft: 10,
  },
  testButtonText: {
    color: mixins.color.blue,
  },
});

export default styles;
