import React from 'react';
import { StyleProp, Text, ViewStyle } from 'react-native';
import styles from './Heading.styles';

const Heading: React.FC<{
  variant?: 'page' | 'section';
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}> = ({ children, variant = 'section', style }) => (
  <Text style={[styles[variant], style]}>{children}</Text>
);

export default Heading;
