import React from 'react';
import { ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useIsWebMobile } from '../../utils';

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: 50,
    paddingRight: 20,
    paddingTop: 10,
  },
  wrapperMobile: {
    paddingHorizontal: 15,
    paddingRight: 15,
  },
  wrapperInner: {
    maxWidth: 1720,
  },
});

const PageWrapper: React.FC<{
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}> = ({ children, style }) => {
  const isWebMobile = useIsWebMobile();

  return (
    <ScrollView style={[styles.wrapper, style, isWebMobile && styles.wrapperMobile]}>
      <View style={styles.wrapperInner}>{children}</View>
    </ScrollView>
  );
};

export default PageWrapper;
